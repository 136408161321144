import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "gatsby";

function Button({
  href,
  to,
  children,
  className,
  primary,
  secondary,
  text,
  outcome,
  big,
  noIcon,
  ...rest
}) {
  const buttonClasses = classnames("btn", className, {
    "btn--primary": primary,
    "btn--secondary": secondary,
    "btn--text": text,
    "btn--outcome": outcome,
    "btn--big": big,
    "btn--noIcon": noIcon,
  });

  return href ? (
    <a href={href} className={buttonClasses} {...rest}>
      {children}
    </a>
  ) : (
    <Link to={to} className={buttonClasses} {...rest}>
      {children}
    </Link>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  text: PropTypes.bool,
  outcome: PropTypes.bool,
  big: PropTypes.bool,
  noIcon: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
};

export default Button;
